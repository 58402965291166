
import {Options, Vue} from "vue-class-component";

@Options({
	beforeCreate() {
		this.query = this.$route?.query?.q ?? "";
	},
})
export default class SearchView extends Vue {
	query = "";

	doSearch() {
		let params = new URLSearchParams(location.search);
		params.set('q', this.query);
		location.search = params.toString();
	}
}
