import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueGoogleMaps from '@fawmi/vue-google-maps'

createApp(App)
  .use(router)
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyB8728SqbLh2epqB7rl5c3I0ln7RxiZnYI',
    }
  })
  .mount('#app')
