import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import SearchView from "@/views/SearchView.vue";
import ProfileView from "@/views/ProfileView.vue";
import DirectoryListingView from "@/views/DirectoryListingView.vue";
import CategoriesView from "@/views/CategoriesView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: HomeView
  },
  {
    path: '/search',
    component: SearchView
  },
  {
    path: '/directory',
    component: DirectoryListingView
  },
  {
    path: '/categories',
    component: CategoriesView
  },
  {
    path: '/profile/:slug',
    component: ProfileView,
    props: true,
  },
  {
    path: '/contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  },
  {
    path: '/about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/faq',
    component: () => import(/* webpackChunkName: "about" */ '../views/FaqView.vue')
  },
  {
    path: '/imprint',
    component: () => import(/* webpackChunkName: "legal" */ '../views/ImprintView.vue')
  },
  {
    path: '/privacy-policy',
    component: () => import(/* webpackChunkName: "legal" */ '../views/PrivacyPolicyView.vue')
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/LoginView.vue')
  },
  {
    path: '/forgot-password',
    component: () => import(/* webpackChunkName: "auth" */ '../views/ResetPasswordView.vue')
  },
  {
    path: '/onboarding',
    component: () => import(/* webpackChunkName: "auth" */ '../views/OnboardingView.vue')
  },
  {
    path: '/editor',
    component: () => import(/* webpackChunkName: "auth" */ '../views/ProfileEditorView.vue')
  },
  {
    path: '/admin/review',
    component: () => import(/* webpackChunkName: "auth" */ '../views/EditorialView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFoundView.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
