
import Navbar from "@/components/Navbar.vue";
import FooterSection from "@/components/FooterSection.vue";
import {Options, Vue} from "vue-class-component";

@Options({
	components: {Navbar, FooterSection},
})
export default class App extends Vue {
}
