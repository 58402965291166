<template>
	<main>
		<h1>Categories</h1>

		<details>
			<summary>States</summary>

			<ul>
				<li>Bayern</li>
				<li>Berlin</li>
				<li>Bremen</li>
			</ul>
		</details>

		<details>
			<summary>Organization</summary>

			<details>
				<summary>Company
					<router-link to="/browse/companies">Browse</router-link>
				</summary>

				<details>
					<summary>ST</summary>
				</details>
				<details>
					<summary>SBA</summary>
				</details>
			</details>
			<details>
				<summary>University</summary>
			</details>
			<details>
				<summary>Ministry</summary>
			</details>
			<details>
				<summary>Association</summary>
			</details>
		</details>
	</main>
</template>

<style lang="scss" scoped>
details {
  details {
    margin-left: 1rem;
  }
}
</style>
