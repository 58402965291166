
import {Options, Vue} from 'vue-class-component';

@Options({})
export default class HomeView extends Vue {
	query = "";

	doSearch() {
		this.$router.push({
			path: "/search",
			query: {
				q: this.query,
			}
		})
	}
}
