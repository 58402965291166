<template>
	<main>
		<h1>Directory Listing</h1>

		<h2>A</h2>

		<ul>
			<li>
				<router-link to="/profile/123-airbus">Airbus</router-link>
			</li>
		</ul>

		<h2>D</h2>

		<ul>
			<li>
				<router-link to="/profile/123-dlr">DLR</router-link>
			</li>
		</ul>

		<h2>T</h2>

		<ul>
			<li>
				<router-link to="/profile/123-trenz">TRENZ</router-link>
			</li>
		</ul>
	</main>
</template>
