
import {Options, Vue} from "vue-class-component";

@Options({
	props: {
		slug: {
			type: String,
			required: true,
		}
	}
})
export default class ProfileView extends Vue {
}
